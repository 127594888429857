<template>
  <div class="main-container">
    <!-- 面包屑 -->
    <div class="question-nav">
      <router-link :to="{name:'Question'}">问答</router-link>
      <span class="slash">></span>
      <span class="current">支付问答</span>
    </div>
    <div class="pay_content">
      <div class="title">支付围观费用</div>
      <div class="content">
        <div class="pay_type_change">请选择支付方式</div>
        <div class="pay_type">
          <span v-for="(item,index) in pay_types"
                @click="change_type(item.type,item.type=='balance')"
                :key="index"
                :class="item.type==type?'active':''">
            <img class="img"
                 :src="item.src">
          </span>
        </div>
        <div class="codeUrl">
          <vue-qr v-if="codeUrl"
                  :text="codeUrl"
                  :size="135"
                  :margin="0"></vue-qr>
        </div>
        <div class="acccount"
             v-if="type=='balance' && balance">
          <p>可用余额<span style="color:#B7383F">￥{{balance | toFixPrice}}</span></p>
          <el-button type="primary"
                     @click="change_type('balance',false)">余额支付</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ali_icon from '../../images/ali_pay@2x.png';
import wx_icon from '../../images/wx_pay@2x.png';
import account_icon from '../../images/account_pay@2x.png';
import VueQr from "vue-qr";
let timer;
export default {
  name: "QuestionPay",
  components: {
    VueQr
  },
  data () {
    return {
      pay_types: [
        { src: ali_icon, type: 'ali' },
        { src: wx_icon, type: 'wx' },
        { src: account_icon, type: 'balance' }
      ],
      type: 'ali',
      codeUrl: '',
      order_number: '',
      order_id: '',
      balance: 0
    };
  },
  async created () {
    this.get_order_number();
    this.get_balance();
  },
  methods: {
    get_balance () {
      this.$http.get('/api/app/balanceCenter').then(data => {
        this.balance = data.balance ? data.balance : '0';
      })
    },
    get_order_number () {
      this.$http.post(`/api/app/order/downOrder`, {
        shop_id: this.$route.params.id,
        type: 17
      }).then(res => {
        if (!res.code) {
          this.order_number = res.order_number;
          this.change_type('ali');
        }
      });
    },
    set_timer () {
      timer = setInterval(async () => {
        let data = await this.$http.post(
          "/api/app/myOrder/detail",
          {
            order_id: this.order_id
          },
          { showLoading: false, isAuthRequest: true }
        );
        if (data.order_status == 2) {
          clearInterval(timer);
          this.$router.push({
            name: "QuestionSuccess",
            params: { ask_id: this.$route.params.ask_id, id: this.$route.params.id }
          });
        }
      }, 1000);
    },
    change_type (type, balance) {
      if (timer) {
        clearInterval(timer);
      }
      this.type = type;
      this.codeUrl = '';
      if (this.order_number) {
        if (this.type != 'balance' || !balance) {
          this.$http.post("/api/app/pay", {
            type: this.type,
            order_number: this.order_number
          }).then(res => {
            this.codeUrl = res.response.code_url;
            this.order_id = res.response.order_id;
            this.set_timer();
          });
        } else {
          this.set_timer();
        }
      }
    }
  },
  beforeDestroy () {
    if (timer) {
      clearInterval(timer);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../css/question.scss";
.pay_content {
  background-color: #fff;
  font-size: 14px;
  .content {
    border: 1px solid #f7f6fb;
    margin: 5px;
    border-radius: 5px 5px 0px 0px;
    padding-bottom: 172px;
  }
  .title {
    height: 67px;
    line-height: 60px;
    padding-left: 19px;
  }
  .pay_type_change {
    height: 50px;
    line-height: 50px;
    color: #333333;
    background-color: #f9f9f9;
    line-height: 50px;
    padding-left: 12px;
    border-radius: 5px 5px 0px 0px;
  }
  .pay_type {
    margin: 30px 0 0 21px;
    span {
      display: inline-block;
      width: 150px;
      height: 50px;
      line-height: 50px;
      margin-right: 40px;
      text-align: center;
      border: 2px solid #eef1f2;
      border-radius: 5px;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      &.active {
        border: 2px solid $primary-color;
      }
      &.active::after {
        content: "";
        position: absolute;
        right: -3px;
        bottom: -6px;
        width: 16px;
        height: 9px;
        background-color: $primary-color;
        transform: rotate(120deg);
        -ms-transform: rotate(120deg); /* IE 9 */
        -moz-transform: rotate(120deg); /* Firefox */
        -webkit-transform: rotate(150deg); /* Safari 和 Chrome */
        -o-transform: rotate(90deg);
      }
    }
    .img {
      width: 78px;
      height: 24px;
      vertical-align: middle;
    }
    span:nth-child(2) .img {
      width: 65px;
    }
    span:nth-child(3) .img {
      width: 91px;
      height: 22px;
    }
  }
  .codeUrl {
    text-align: center;
    margin-top: 94px;
    min-height: 80px;
  }
  .acccount {
    float: right;
    text-align: center;
    margin-right: 36px;
    p {
      margin-bottom: 20px;
      font-size: 18px;
    }
    button {
      width: 148px;
    }
  }
}
</style>